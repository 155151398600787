// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Youtube_portrait__vIJI2 {
  margin: 2.5rem 1rem 0 1rem; }

.Youtube_landscape__XZ3RT {
  margin-top: 1rem; }

.Youtube_cover_windowed__-2ZgW {
  opacity: 0;
  border-radius: 20px;
  overflow: hidden;
  background-color: red;
  position: absolute; }

.Youtube_frame_windowed__WYL-v {
  border-radius: 20px;
  overflow: hidden; }

.Youtube_frame_fullscreen__VlEuv {
  position: absolute;
  margin-left: 0px;
  margin-top: 0px;
  z-index: 0;
  /* background-color:black; */ }
`, "",{"version":3,"sources":["webpack://./src/components/ui/Youtube.module.scss"],"names":[],"mappings":"AAAA;EACE,0BAA0B,EAAA;;AAG5B;EACE,gBAAgB,EAAA;;AAGlB;EACE,UAAU;EACV,mBAAmB;EACnB,gBAAgB;EAChB,qBAAqB;EACrB,kBAAkB,EAAA;;AAGpB;EACE,mBAAmB;EACnB,gBAAgB,EAAA;;AAGlB;EACE,kBAAkB;EAClB,gBAAgB;EAChB,eAAe;EACf,UAAU;EACV,4BAAA,EAA6B","sourcesContent":[".portrait{\n  margin: 2.5rem 1rem 0 1rem; \n}\n\n.landscape{\n  margin-top: 1rem;\n}\n\n.cover_windowed{\n  opacity: 0;\n  border-radius: 20px;\n  overflow: hidden;\n  background-color: red;\n  position: absolute;\n}\n\n.frame_windowed{\n  border-radius: 20px;\n  overflow: hidden;\n}\n\n.frame_fullscreen{\n  position: absolute;\n  margin-left: 0px;\n  margin-top: 0px;\n  z-index: 0;\n  /* background-color:black; */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"portrait": `Youtube_portrait__vIJI2`,
	"landscape": `Youtube_landscape__XZ3RT`,
	"cover_windowed": `Youtube_cover_windowed__-2ZgW`,
	"frame_windowed": `Youtube_frame_windowed__WYL-v`,
	"frame_fullscreen": `Youtube_frame_fullscreen__VlEuv`
};
export default ___CSS_LOADER_EXPORT___;
