import styles from "./Announcement.module.scss";

function Announcement() {
  let json = {}
  let content = <div className={styles.container}>
                  <h1 className={`text-center ${styles.header}`}>Welcome!</h1>
                  <div className={styles.content}>
                    Looking for the perfect movie to watch tonight? Look no further than Haveiseen! 
                    With our continuous trailer play and customizable genre options, finding the perfect 
                    film is just a few clicks away. Simply choose your preferred genres and let Haveiseen 
                    do the rest.
                  </div>

                  <div className={`text-center ${styles.header}`}>Whats new?</div>
                  <div className={styles.content}>
                    <ul className="m-2">
                      <li className={styles.item}>Added Announcements PopUp for latest news</li>
                      <li className={styles.item}>Fixed mobile UI for landscape</li>
                      <li className={styles.item}>Added Movie Title</li>
                    </ul>
                  </div>

                  <div className={`text-center ${styles.header}`}>Feedback?</div>
                  <div className={`text-center ${styles.content}`}>
                    contact@haveiseen.com
                  </div>
                </div>

  json["news-2022-01-21x"] = content
  return json;
}

export default Announcement;
