import React from "react";
import { useState, useEffect, useMemo } from "react";
import Modal from "react-modal";

import "./ReactModal.css";

function ReactModal(props) {
  const { modelSetting, closeModal, contentSetting } = props;
  const [modalIsOpen, setModalIsOpen] = useState(modelSetting);
  const [content, setContent] = useState();


  let subtitle;
  const btnColor = "#2C2B3B";

  function figureContent() {
    return contentSetting;
  }

  useEffect(() => {
    setContent(figureContent());
    setModalIsOpen(modelSetting);
  });


  return (
    <div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        className="Modal flex justify-center w-full h-screen"
        overlayClassName="Overlay"
        ariaHideApp={false}
        closeTimeoutMS={250}
      >
        {content}
        <div className="modal-close-btn" onClick={closeModal}>
          <svg width="62" height="62" viewBox="0 0 62 62">
            <circle cx="31" cy="31" r="31" fill={btnColor} id="modal-closer"/>
          </svg>
          <div className="modal-close-btn-text" id="modal-closer">X</div>
        </div>
      </Modal>
    </div>
  );
}

export default ReactModal;
