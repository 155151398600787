import React, { useState, useEffect } from "react";
import CircleButton from "./CircleButton";
import PlayerButton from "./PlayerButton";
import axios from "axios";
import { Youtube } from "./Youtube";
import styles from "./Trailer.module.scss";
import GenresList from "./GenresList";

function Trailer(props) {
  const { inDev, openModal, closeModal } = props;
  const darkScreen = "nvvtpyVJf1k";

  const defaultGenres = [
    12, //Adventure
    28, //Action
    27, // Horror"
  ];

  const screenModes = {
    Fullscreen: "fullscreen",
    Windowed: "windowed",
  };

  const screenOrientations = {
    Portrait: "portrait",
    Landscape: "landscape",
  };

  const roleOptions = {
    Admin: "admin",
    user: "user",
  };

  const [userRole, setUserRole] = useState();
  const [filterJson, setfilterJson] = useState(createFilterJson());
  const [playingGenreId, setPlayingGenreId] = useState(
    defaultGenres[Math.floor(Math.random() * defaultGenres.length)]
  );

  const [beganPlaying, setBeganPlaying] = useState(false);
  const [query, setQuery] = useState();
  const [playListIndex, setplaylistIndex] = useState(0);
  const [trailers, setTrailers] = useState([]);
  const [apiPage, setApiPage] = useState(0);
  const [youtubeID, setYoutubeID] = useState(darkScreen);
  const [movieTitle, setMovieTitle] = useState("Haveiseen");
  const [genres, setGenres] = useState("");
  const [releaseYear, setReleaseYear] = useState("");

  const [screenOrientation, setScreenOrientation] = useState(
    determineScreenOrientation()
  );
  const [screenMode, setScreenMode] = useState(screenModes.Windowed);

  useEffect(() => {
    setUserRole(determineUserRole());
    // getMoreTrailer();

    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  function updateDimensions() {
    let screenOrientation = determineScreenOrientation();

    setScreenOrientation(screenOrientation);
  }

  function determineScreenOrientation() {
    if (window.innerWidth > window.innerHeight) {
      return screenOrientations.Landscape;
    } else {
      return screenOrientations.Portrait;
    }
  }

  const TRAILERS_URL = `${window.location.origin.replace(
    "3006",
    "3005"
  )}${"/api/v1/trailers?"}`;

  function determineUserRole() {
    if (inDev) {
      return roleOptions.Admin;
    } else {
      return "user";
    }
  }

  function createFilterJson(year) {
    let currentYear = new Date().getFullYear() - 1;

    if (typeof year === "undefined") {
      year = currentYear - 5;
    }

    return `{ "modifier": "range", 
              "year_from": "${year}",
              "year_to": "${currentYear}" }`;
  }

  // https://api.themoviedb.org/3/discover/movie?api_key=18a5a4b502db8f37d971cfc70c51256e&page=4&language=en&with_genres=28&sort_by=revenue.desc&primary_release_year=2021

  function updateYoutube(new_trailers_list, playIndex) {
    setQuery(new_trailers_list[playIndex].query);
    setTrailers(new_trailers_list);
    setYoutubeID(new_trailers_list[playIndex].youtube_id);
    setMovieTitle(new_trailers_list[playIndex].title);
    setGenres(new_trailers_list[playIndex].genres);
    setReleaseYear(new_trailers_list[playIndex].year);
  }

  function getMoreTrailer() {
    let playIndex = playListIndex + 1;
    let page = apiPage + 1;
    setplaylistIndex(playIndex);

    trailerApi(playingGenreId, page, filterJson).then((items) => {
      let new_trailers_list = [];
      new_trailers_list = trailers.concat(items.trailers);
      setApiPage(page);
      updateYoutube(new_trailers_list, playIndex);
    });
  }

  function generateFilterJson(filterJson) {
    let parcedJson = JSON.parse(filterJson);
    let filter_json = {};
    let currentYear = new Date().getFullYear() - 1;

    filter_json["modifier"] = parcedJson["modifier"];
    filter_json["year_from"] = parcedJson["year_from"];
    filter_json["year_to"] = String(currentYear);

    return JSON.stringify(filter_json);
  }

  function trailerApi(genreId, page, filterJson) {
    var filterJson =
      typeof filterJson === "undefined"
        ? createFilterJson()
        : generateFilterJson(filterJson);
    let filter_json = `&filters_json=${filterJson}`;

    genreId = `&genre_id=${genreId}`;
    page = `&page=${page}`;
    let apiURL = TRAILERS_URL + genreId + page + filter_json;
    return axios.get(apiURL).then((response) => response.data);
  }

  function playPrevious() {
    let playIndex = playListIndex;
    if (playIndex > 0) {
      playIndex -= 1;
      setplaylistIndex(playIndex);
      setYoutubeID(trailers[playIndex].youtube_id);
      setMovieTitle(trailers[playIndex].title);
    } else {
      alert("begining of list");
    }
  }

  function playNext() {
    let playIndex = playListIndex;
    if (playIndex < trailers.length - 1) {
      playIndex += 1;
      setplaylistIndex(playIndex);
      updateYoutube(trailers, playIndex);
    } else {
      getMoreTrailer();
    }
  }

  function beginPlaying() {
    if (beganPlaying == false) {
      playNext();
      setBeganPlaying(true);
    }
  }

  function clearTrailersList() {
    let playIndex = 0;
    let page = 1;

    setTrailers([]);
    setplaylistIndex(playIndex);
    setApiPage(page);
  }

  function switchGenre(newGenreId) {
    let playIndex = 0;
    clearTrailersList();

    setPlayingGenreId(newGenreId);
    trailerApi(newGenreId, 1, filterJson).then((items) => {
      let new_trailers_list = [];
      new_trailers_list = items.trailers;

      updateYoutube(new_trailers_list, playIndex);
    });
  }

  function toggleScreenScreenMode() {
    var newScreenMode;

    if (screenMode == screenModes.Fullscreen) {
      newScreenMode = screenModes.Windowed;
    } else {
      newScreenMode = screenModes.Fullscreen;
    }

    setScreenMode(newScreenMode);
  }

  function pullTrailersByFilter(e) {
    let filterJson = e.currentTarget.dataset.filter;
    let playIndex = 0;
    let page = 1;
    let newFilter = createFilterJson(JSON.parse(filterJson)["year_from"]);

    clearTrailersList();
    setfilterJson(newFilter);

    trailerApi(playingGenreId, page, filterJson).then((items) => {
      let new_trailers_list = [];
      new_trailers_list = items.trailers;

      updateYoutube(new_trailers_list, playIndex);
    });
  }

  function yearFilter(modifier, years) {
    let year = new Date().getFullYear() - years;

    let dataObject = {};
    dataObject["modifier"] = modifier;
    dataObject["year_from"] = String(year);

    return JSON.stringify(dataObject);
  }

  function testAlert() {
    alert("Discover movies your way, IFTTT");
  }

  function initateOpenModal() {
    var genreList = (
      <GenresList
        closeModal={closeModal}
        switchGenre={switchGenre}
      ></GenresList>
    );
    openModal(genreList);
  }

  return (
    <div>
      <div className="grid place-items-center">
        {inDev ? (
          <div className={styles.in_dev}>
            <span>Genre</span> - <span>{playingGenreId}</span>
          </div>
        ) : (
          <div
            className={
              screenOrientation === "portrait"
                ? styles.portrait
                : styles.landscape
            }
          >
            {movieTitle}
          </div>
        )}
      </div>
      <Youtube
        inDev={inDev}
        role={userRole}
        youtubeID={youtubeID}
        genres={genres}
        year={releaseYear}
        playNextFunc={playNext}
        beginPlayingFunc={beginPlaying}
        screenModeSetting={screenMode}
        screenOrientationSetting={screenOrientation}
        screenReset={true}
      ></Youtube>

      <PlayerButton
        buttonType="Previous"
        playerAction={playPrevious}
        screenOrientationSetting={screenOrientation}
      />

      <PlayerButton
        buttonType="Next"
        playerAction={playNext}
        screenOrientationSetting={screenOrientation}
      />

      <PlayerButton
        buttonType="Genre"
        playerAction={initateOpenModal}
        playingGenre={playingGenreId}
        screenOrientationSetting={screenOrientation}
      />

      <PlayerButton
        buttonType="FullScreen"
        playerAction={toggleScreenScreenMode}
        screenOrientationSetting={screenOrientation}
      />

      <div className={styles.filters}>
        <CircleButton
          buttonType="1-year"
          buttonText="Past 1 Year"
          playerAction={pullTrailersByFilter}
          dataYear={yearFilter("range", 1)}
        ></CircleButton>
        <CircleButton
          buttonType="5-year"
          buttonText="Past 5 Years"
          playerAction={pullTrailersByFilter}
          dataYear={yearFilter("range", 5)}
        ></CircleButton>
        <CircleButton
          buttonType="10-year"
          buttonText="Past 10 Year"
          playerAction={pullTrailersByFilter}
          dataYear={yearFilter("range", 10)}
        ></CircleButton>
      </div>

      {userRole === roleOptions.Admin && inDev && (
        <div className={styles.debug_console}>
          <div>
            <span>
              {genres} - index: {playListIndex}/{trailers.length}
            </span>
          </div>
          <div>
            <span>{query}</span>
          </div>
        </div>
      )}
    </div>
  );
}

export default Trailer;
