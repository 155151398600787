import "./App.scss";
import Trailer from "./components/ui/Trailer";
import TagManager from "react-gtm-module";
import React, { useState, useEffect } from "react";
import ReactModal from "./components/ReactModal/ReactModal";
import Announcement from "./components/ui/Announcement";

const tagManagerArgs = {
  gtmId: "GTM-THD3HW4",
};

function GoogleAnalyticsOn() {
  if (window.location.origin.includes("localhost")) {
    return false;
  } else {
    return true;
  }
}

function simulationOn() {
  return true;
}

function inDev() {
  if (window.location.origin.includes("localhost")) {
    return !simulationOn();
  } else {
    return false;
  }
}

if (GoogleAnalyticsOn()) {
  TagManager.initialize(tagManagerArgs);
}

function App() {
  const [modalContent, setModalContent] = useState();
  const [modalIsOpen, setIsOpen] = useState(false);
  const [makingAnnoucement, setMakingAnnoucement] = useState(false);
  const currentAnnouncement = Announcement();
  const announcementKey = Object.keys(currentAnnouncement)[0]

  useEffect(() => {
    setModalContent(currentAnnouncement[announcementKey]);

    if (modalShouldOpen()) {
      makeTheAnnouncement();
    }
  }, [])

  function makeTheAnnouncement() {
    setMakingAnnoucement(true);
    setIsOpen(true);
  }

  function modalShouldOpen() {
    if (showLatestNews()) {
      return true;
    }
  }

  function showLatestNews() {
    let newsStatus = localStorage.getItem(announcementKey);
    return (newsStatus === null);
  }

  function openModal(content) {
    setModalContent(content);
    setIsOpen(true);
  }

  function closeModal() {
    console.log("closing news");
    if (makingAnnoucement) {
      localStorage.setItem(announcementKey, "closed");
      setMakingAnnoucement(false)
    }
    setIsOpen(false);
  }

  return (
    <div>
      <ReactModal
        modelSetting={modalIsOpen}
        contentSetting={modalContent}
        closeModal={closeModal}
      />
      <Trailer inDev={inDev()} 
               openModal={openModal}
               closeModal={closeModal}>
      </Trailer>
    </div>
  );
}

export default App;