// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CircleButton_button__FpgVj {
  display: inline-block;
  position: relative;
  margin-left: 2px;
  margin-right: 2px;
  height: 70px;
  font-size: smaller;
  color: lightgray; }

.CircleButton_text__tVZMa {
  position: absolute;
  font-size: 111%;
  font-weight: 500;
  text-align: center;
  margin-left: -5px;
  color: white;
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 60px; }
`, "",{"version":3,"sources":["webpack://./src/components/ui/CircleButton.module.scss"],"names":[],"mappings":"AAAA;EACE,qBAAqB;EACrB,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB;EACjB,YAAY;EACZ,kBAAkB;EAClB,gBAAgB,EAAA;;AAGlB;EACE,kBAAkB;EAClB,eAAe;EACf,gBAAgB;EAChB,kBAAkB;EAClB,iBAAiB;EACjB,YAAY;EAEZ,aAAa;EACb,uBAAuB;EACvB,sBAAsB;EACtB,YAAY,EAAA","sourcesContent":[".button{\n  display: inline-block;\n  position: relative;\n  margin-left: 2px;\n  margin-right: 2px;\n  height: 70px;\n  font-size: smaller;\n  color: lightgray;\n}\n\n.text {\n  position: absolute;\n  font-size: 111%;\n  font-weight: 500;\n  text-align: center;\n  margin-left: -5px;\n  color: white;\n\n  display: flex;\n  justify-content: center;\n  flex-direction: column;\n  height: 60px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": `CircleButton_button__FpgVj`,
	"text": `CircleButton_text__tVZMa`
};
export default ___CSS_LOADER_EXPORT___;
