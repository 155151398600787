import React, { useState, useEffect } from "react";
import ButtonIcon from "./ButtonIcon";
import { Genres } from "./GenreButton";
import styles from "./PlayerButton.module.scss";

function PlayerButton(props) {
  const { playerAction, buttonType, playingGenre, screenOrientationSetting } =
    props;
  const [buttonLabel, setButtonLabel] = useState();
  const [screenOrientation, setScreenOrientation] = useState();

  useEffect(() => {
    setScreenOrientation(screenOrientationSetting);
    let buttonLabel = buttonType;
    if (buttonLabel === "Genre") {
      buttonLabel = Genres[playingGenre].name;
    }
    setButtonLabel(buttonLabel);
  });

  function figureBtnStyle() {
    if (buttonType == "Previous") {
      return styles.previous;
    } else if (buttonType == "Next") {
      return styles.next;
    } else if (buttonType == "Genre") {
      return styles.genre;
    } else if (buttonType == "FullScreen") {
      return styles.fullscreen;
    }
  }

  return (
    <div
      className={
        screenOrientation === "portrait" ? styles.portrait : styles.landscape
      }
    >
      <div className={figureBtnStyle()}>
        <div className={styles.background} onClick={playerAction}>
          <div className="flex justify-center">
            <p className={styles.text}>{buttonLabel}</p>
          </div>
          <div
            className={ styles.circle }
          ><ButtonIcon buttonType={buttonType} playingGenre={playingGenre} screenOrientationSetting={screenOrientation} /></div>
        </div>
      </div>
    </div>
  );
}

export default PlayerButton;
