// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Trailer_in_dev__UZhc1 {
  position: absolute;
  top: 1rem; }

.Trailer_landscape__NKlYO {
  width: 100%;
  top: 1%;
  position: absolute;
  text-align: center;
  z-index: 100; }

.Trailer_portrait__1XFp3 {
  width: 100%;
  top: 0.5rem;
  position: absolute;
  text-align: center;
  z-index: 100; }

.Trailer_debug_console__OkAlV {
  font-family: 'Consolas', monospace;
  font-size: small;
  color: lime;
  width: 100%;
  position: fixed;
  bottom: 0;
  background-color: black; }

.Trailer_filters__B\\+edS {
  position: fixed;
  bottom: 0px;
  width: 100%;
  display: flex;
  justify-content: center; }
`, "",{"version":3,"sources":["webpack://./src/components/ui/Trailer.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,SAAS,EAAA;;AAGX;EACE,WAAW;EACX,OAAO;EACP,kBAAkB;EAClB,kBAAkB;EAClB,YAAY,EAAA;;AAGd;EACE,WAAW;EACX,WAAW;EACX,kBAAkB;EAClB,kBAAkB;EAClB,YAAY,EAAA;;AAGd;EACE,kCAAkC;EAClC,gBAAgB;EAChB,WAAW;EACX,WAAW;EACX,eAAe;EACf,SAAS;EACT,uBAAuB,EAAA;;AAGzB;EACE,eAAe;EACf,WAAW;EACX,WAAW;EACX,aAAa;EACb,uBAAuB,EAAA","sourcesContent":[".in_dev{\n  position: absolute;\n  top: 1rem;\n}\n\n.landscape{\n  width: 100%;\n  top: 1%;\n  position: absolute;\n  text-align: center;\n  z-index: 100;\n}\n\n.portrait{\n  width: 100%;\n  top: 0.5rem;\n  position: absolute;\n  text-align: center;\n  z-index: 100;\n}\n\n.debug_console {\n  font-family: 'Consolas', monospace;\n  font-size: small;\n  color: lime;\n  width: 100%;\n  position: fixed;\n  bottom: 0;\n  background-color: black;\n}\n\n.filters{\n  position: fixed;\n  bottom: 0px;\n  width: 100%;\n  display: flex;\n  justify-content: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"in_dev": `Trailer_in_dev__UZhc1`,
	"landscape": `Trailer_landscape__NKlYO`,
	"portrait": `Trailer_portrait__1XFp3`,
	"debug_console": `Trailer_debug_console__OkAlV`,
	"filters": `Trailer_filters__B+edS`
};
export default ___CSS_LOADER_EXPORT___;
