import React, { useState, useEffect } from "react";
import { Genres } from "../ui/GenreButton";
import styles from "./ButtonIcon.module.scss";

function ButtonIcon(props) {
  const { buttonType, playingGenre, screenOrientationSetting } = props;
  const [screenOrientation, setScreenOrientation] = useState();

  useEffect(() => {
    setScreenOrientation(screenOrientationSetting);
  });

  function renderPrevious() {
    return (
      <svg viewBox="0 0 50 50">
        <g transform="translate(0)">
          <g transform="translate(31.671 21.284) rotate(180)">
            <path
              d="M7.8.789a2,2,0,0,1,3.412,0l5.936,11.7a2,2,0,0,1-1.706,3.044H3.568a2,2,0,0,1-1.706-3.044Z"
              transform="translate(31.503 3.841) rotate(90)"
              fill="#aaa"
            />
          </g>
          <g transform="translate(45.671 21.284) rotate(180)">
            <path
              d="M7.8.789a2,2,0,0,1,3.412,0l5.936,11.7a2,2,0,0,1-1.706,3.044H3.568a2,2,0,0,1-1.706-3.044Z"
              transform="translate(31.503 3.841) rotate(90)"
              fill="#aaa"
            />
          </g>
        </g>
      </svg>
    );
  }

  function renderNext() {
    return (
      <svg viewBox="0 0 50 50">
        <g transform="translate(29.703 15.877) rotate(180)">
          <g transform="translate(31.671 21.284) rotate(180)">
            <path
              d="M7.8.789a2,2,0,0,1,3.412,0l5.936,11.7a2,2,0,0,1-1.706,3.044H3.568a2,2,0,0,1-1.706-3.044Z"
              transform="translate(31.503 3.841) rotate(90)"
              fill="#aaa"
            />
          </g>
          <g transform="translate(45.671 21.284) rotate(180)">
            <path
              d="M7.8.789a2,2,0,0,1,3.412,0l5.936,11.7a2,2,0,0,1-1.706,3.044H3.568a2,2,0,0,1-1.706-3.044Z"
              transform="translate(31.503 3.841) rotate(90)"
              fill="#aaa"
            />
          </g>
        </g>
      </svg>
    );
  }

  function renderGenre() {
    return <div>{Genres[playingGenre].emoji}</div>;
  }

  function renderFullscreen() {
    return (
      <div className={screenOrientation === "portrait" ? styles.fullscreen_portrait : styles.fullscreen_landscape}>
        <svg viewBox="0 0 50 50">
          <g id="Group_17" data-name="Group 17" transform="translate(-816 -255)">
            <g
              id="Group_13"
              data-name="Group 13"
              transform="translate(817.5 256.5)"
            >
              <line
                id="Line_6"
                data-name="Line 6"
                y2="8"
                fill="none"
                stroke="#fff"
                strokeLinecap="square"
                strokeWidth="3"
              />
              <line
                id="Line_7"
                data-name="Line 7"
                y2="8"
                transform="translate(8) rotate(90)"
                fill="none"
                stroke="#fff"
                strokeLinecap="square"
                strokeWidth="3"
              />
            </g>
            <g
              id="Group_15"
              data-name="Group 15"
              transform="translate(817.5 272.5)"
            >
              <line
                id="Line_6-2"
                data-name="Line 6"
                y1="8"
                fill="none"
                stroke="#fff"
                strokeLinecap="square"
                strokeWidth="3"
              />
              <line
                id="Line_7-2"
                data-name="Line 7"
                y1="8"
                transform="translate(0 8) rotate(-90)"
                fill="none"
                stroke="#fff"
                strokeLinecap="square"
                strokeWidth="3"
              />
            </g>
            <g
              id="Group_14"
              data-name="Group 14"
              transform="translate(844.819 256.5) rotate(90)"
            >
              <line
                id="Line_6-3"
                data-name="Line 6"
                y2="8"
                fill="none"
                stroke="#fff"
                strokeLinecap="square"
                strokeWidth="3"
              />
              <line
                id="Line_7-3"
                data-name="Line 7"
                y2="8"
                transform="translate(8) rotate(90)"
                fill="none"
                stroke="#fff"
                strokeLinecap="square"
                strokeWidth="3"
              />
            </g>
            <g
              id="Group_16"
              data-name="Group 16"
              transform="translate(836.819 280.5) rotate(-90)"
            >
              <line
                id="Line_6-4"
                data-name="Line 6"
                y1="8"
                fill="none"
                stroke="#fff"
                strokeLinecap="square"
                strokeWidth="3"
              />
              <line
                id="Line_7-4"
                data-name="Line 7"
                y1="8"
                transform="translate(0 8) rotate(-90)"
                fill="none"
                stroke="#fff"
                strokeLinecap="square"
                strokeWidth="3"
              />
            </g>
          </g>
        </svg>
      </div>

    );
  }

  function figure_icon_style() {
    return screenOrientation === "portrait" ? styles.icon_portrait : styles.icon_landscape
  }

  function figure_emoji_style() {
    return screenOrientation === "portrait" ? styles.emoji_portrait : styles.emoji_landscape
  }

  return (
    <div className={buttonType == "Genre" ? figure_emoji_style() : figure_icon_style()}>
      {buttonType == "Previous" ? renderPrevious() : ""}
      {buttonType == "Next" ? renderNext() : ""}
      {buttonType == "Genre" ? renderGenre() : ""}
      {buttonType == "FullScreen" ? renderFullscreen() : ""}
    </div>
  );
}

export default ButtonIcon;
