import React from "react";
import styles from "./GenreButton.module.scss";


export const Genres = {
  28: {
    emoji: '🔫',
    name: "Action",
  },
  12: {
    emoji: '🗺',
    name: "Adventure",
  },
  16: {
    emoji: '🧞‍♂️',
    name: "Animation",
  },
  35: {
    emoji: '🤣',
    name: "Comedy",
  },
  80: {
    emoji: '🕵️‍♂️',
    name: "Crime",
  },
  99: {
    emoji: '🎥',
    name: "Documentary",
  },
  18: {
    emoji: '🎭',
    name: "Drama",
  },
  10751: {
    emoji: '🏠',
    name: "Family" 
  },
  14: {
    emoji: '🧚‍♀️',
    name: "Fantasy",
  },
  10769: {
    emoji: '👄',
    name: "Foreign" 
  },
  36: {
    emoji: '⏳',
    name: "History",
  },
  27: {
    emoji: '🧟‍♀️',
    name: "Horror",
  },
  10402: {
    emoji: '🎸',
    name: "Music" 
  },
  9648: {
    emoji: '❓',
    name:"mystery" 
  },
  10749: {
    emoji: '💕',
    name: "Romance" 
  },
  878: {
    emoji: '🛸',
    name: "Sci-Fi",
  },
  53: {
    emoji: '💀',
    name: "Thriller",
  },
  10752: {
    emoji: '🪖',
    name: "War" 
  },
  37: {
    emoji: '🤠',
    name: "Western",
  },
}

function GenreButton(props) {
  const switchGenre = props.switchGenre;
  const closeModal = props.closeModal;
  const genre = props.genre;

  function initSwitchGenre(event) {
    let genreId = event.currentTarget.dataset.genreId
    closeModal();
    switchGenre(genreId);
  }
  
  return (
      <div onClick={initSwitchGenre} data-genre-id={genre}>
        <div className="flex justify-center">
          <div className={styles.emoji}>{Genres[genre].emoji}</div>
        </div>
        <svg width="62" height="62" viewBox="0 0 62 62">
          <circle cx="31" cy="31" r="31" fill="#39384e" />
        </svg>
        <div className="text-center">
          <div className={styles.genre_title}>
            {Genres[genre].name}
          </div>
        </div>
      </div>
  );
}

export default GenreButton;