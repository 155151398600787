import React from "react";
import GenreButton, { Genres } from "../ui/GenreButton";
import styles from "./GenresList.module.scss";

function GenresList(props) {
  const { switchGenre, closeModal} = props;
  let genres = Genres

  const items = []

  Object.keys(genres).forEach((id) => {
    items.push(
      <div className={styles.button} key={id}>
        <GenreButton genre={id} closeModal={closeModal} switchGenre={switchGenre}/>
      </div>
    )});

    return (
      <div className="genres xl:w-1/2 my-auto ml-8">
        {items}
      </div>
    )
  }

export default GenresList;
