import React, { useState, useEffect } from "react";
import ReactPlayer from "react-player/youtube";
import _debounce from "lodash.debounce";
import styles from "./Youtube.module.scss";

export const Youtube = (props) => {
  const {
    inDev,
    role,
    playNextFunc,
    beginPlayingFunc,
    youtubeID,
    screenModeSetting,
    screenOrientationSetting,
  } = props;

  const autoPlayNext = playNextFunc;
  const beginPlaying = beginPlayingFunc;
  const player = React.createRef();
  const [urlBase, setUrlBase] = useState(role);
  const [screenMode, setScreenMode] = useState(screenModeSetting);
  const [screenOrientation, setScreenOrientation] = useState(
    screenOrientationSetting
  );
  const [pointerEvent, setpointerEvent] = useState("none");
  const [width, setWidth] = useState(resetWidth());
  const [height, setHeight] = useState(resetHeight());

  useEffect(() => {
    setScreenMode(screenModeSetting);
    setScreenOrientation(screenOrientationSetting);
    setWidth(resetWidth());
    setHeight(resetHeight());

    if (role === "admin") {
      setUrlBase("https://www.youtube.com/watch?v=");
    } else {
      setUrlBase("https://www.youtube-nocookie.com/watch?v=");
    }
  });

  function resetWidth() {
    const windowWidth = window.innerWidth;
    if (screenMode === undefined || screenMode == "windowed") {
      if (window.innerWidth > window.innerHeight) {
        return windowWidth * 0.65;
      } else {
        return windowWidth;
      }
    } else {
      return windowWidth;
    }
  }

  function resetHeight() {
    if (screenMode === undefined || screenMode == "windowed") {
      if (window.innerWidth > window.innerHeight) {
        return window.innerHeight * 0.75;
      } else {
        return window.innerWidth / 2;
      }
    } else {
      return window.innerHeight;
    }
  }

  function lockVideo() {
    if (role !== "admin") {
      setpointerEvent("none");
    }
  }

  function videoEnded() {
    autoPlayNext();
  }

  function setVideoDuration(value) {
    // this.setState({
    //   duration: value,
    // });
  }

  function trackVideoProgress(value) {
    // if (value.playedSeconds > this.state.duration - 5 ) {
    //   videoEnded();
    // }
  }

  function isAdmin() {
    return role === "admin";
  }

  function inDevVideoOff() {
    return false;
  }

  function figureOrientation() {
    return styles.portrait;
  }

  function playVideo() {
    return !window.location.origin.includes("localhost")
  }

  return (
    <section>
      {inDev && isAdmin() && inDevVideoOff() ? (
        <div className="grid place-items-center">
          <div className="mt-40">
            <span>replaced screen with youtube ID</span> -{" "}
            <span>{youtubeID}</span>
          </div>
        </div>
      ) : (
        <div
          className={`${
            screenOrientation == "portrait" ? styles.portrait : styles.landscape
          } flex justify-center`}
        >
          <div
            className={screenMode == "windowed" ? styles.cover_windowed : ""}
            style={{ width: width, height: height }}
            onClick={beginPlaying}
          ></div>
          <div
            className={
              screenMode == "windowed"
                ? styles.frame_windowed
                : styles.frame_fullscreen
            }
          >
            <ReactPlayer
              ref={player}
              url={`${urlBase}${youtubeID}`}
              playing={playVideo()}
              muted={false}
              controls={false}
              width={width}
              height={height}
              style={{ pointerEvents: pointerEvent }}
              config={{
                youtube: {
                  playerVars: {
                    showinfo: 0,
                    allowFullScreen: "allowFullScreen",
                  },
                },
              }}
              id="trailer-play"
              onStart={lockVideo}
              onDuration={setVideoDuration}
              onProgress={trackVideoProgress}
              onEnded={videoEnded}
            />
          </div>
        </div>
      )}
    </section>
  );
};
