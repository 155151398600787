// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.GenreButton_genre_title__5f0KI {
  margin-left: -28px; }

.GenreButton_emoji__C3jbc {
  position: absolute;
  font-size: xx-large;
  margin-top: 7px;
  margin-left: -28px; }
`, "",{"version":3,"sources":["webpack://./src/components/ui/GenreButton.module.scss"],"names":[],"mappings":"AACA;EACE,kBAAkB,EAAA;;AAGpB;EACE,kBAAkB;EAClB,mBAAmB;EACnB,eAAe;EACf,kBAAkB,EAAA","sourcesContent":["\n.genre_title{\n  margin-left: -28px;\n}\n\n.emoji {\n  position: absolute;\n  font-size: xx-large;\n  margin-top: 7px;\n  margin-left: -28px;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"genre_title": `GenreButton_genre_title__5f0KI`,
	"emoji": `GenreButton_emoji__C3jbc`
};
export default ___CSS_LOADER_EXPORT___;
