// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-close-btn{
  position: absolute;
  top: 15px;
  right: 15px;
}

.modal-close-btn-text{
  margin-top: -45px;
  margin-left: 26px;
}

.Modal {
  background-color: #191832;
  z-index: 20;
}

.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgb(0, 0, 0, 0.50);
  z-index: 120;
}

.ReactModal__Overlay {
  transform: translateY(100vh);
  transition: all 250ms ease-out;
}

.ReactModal__Overlay--after-open{
  transform: translateY(0px);
}

.ReactModal__Overlay--before-close{
  transform: translateY(100vh);
}`, "",{"version":3,"sources":["webpack://./src/components/ReactModal/ReactModal.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,SAAS;EACT,WAAW;AACb;;AAEA;EACE,iBAAiB;EACjB,iBAAiB;AACnB;;AAEA;EACE,yBAAyB;EACzB,WAAW;AACb;;AAEA;EACE,eAAe;EACf,MAAM;EACN,OAAO;EACP,QAAQ;EACR,SAAS;EACT,oCAAoC;EACpC,YAAY;AACd;;AAEA;EACE,4BAA4B;EAC5B,8BAA8B;AAChC;;AAEA;EACE,0BAA0B;AAC5B;;AAEA;EACE,4BAA4B;AAC9B","sourcesContent":[".modal-close-btn{\n  position: absolute;\n  top: 15px;\n  right: 15px;\n}\n\n.modal-close-btn-text{\n  margin-top: -45px;\n  margin-left: 26px;\n}\n\n.Modal {\n  background-color: #191832;\n  z-index: 20;\n}\n\n.Overlay {\n  position: fixed;\n  top: 0;\n  left: 0;\n  right: 0;\n  bottom: 0;\n  background-color: rgb(0, 0, 0, 0.50);\n  z-index: 120;\n}\n\n.ReactModal__Overlay {\n  transform: translateY(100vh);\n  transition: all 250ms ease-out;\n}\n\n.ReactModal__Overlay--after-open{\n  transform: translateY(0px);\n}\n\n.ReactModal__Overlay--before-close{\n  transform: translateY(100vh);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
