// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.GenresList_button__0wx9B {
  display: inline-block;
  position: relative;
  margin-left: 2px;
  margin-right: 2px;
  height: 70px;
  font-size: smaller;
  color: lightgray;
  width: 90px; }
`, "",{"version":3,"sources":["webpack://./src/components/ui/GenresList.module.scss"],"names":[],"mappings":"AAAA;EACE,qBAAqB;EACrB,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB;EACjB,YAAY;EACZ,kBAAkB;EAClB,gBAAgB;EAChB,WAAW,EAAA","sourcesContent":[".button {\n  display: inline-block;\n  position: relative;\n  margin-left: 2px;\n  margin-right: 2px;\n  height: 70px;\n  font-size: smaller;\n  color: lightgray;\n  width: 90px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": `GenresList_button__0wx9B`
};
export default ___CSS_LOADER_EXPORT___;
