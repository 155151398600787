// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Announcement_container__nsbDi {
  margin-top: 10vh;
  max-height: 80vh;
  max-width: 80vw; }

.Announcement_header__GEazL {
  margin: 20px 10px 10px 10px;
  font-size: x-large;
  font-weight: 700; }

.Announcement_content__jm2AL {
  margin: 10px;
  font-size: large; }

.Announcement_item__i0dBT {
  list-style-type: disc;
  display: list-item;
  list-style-position: outside; }
`, "",{"version":3,"sources":["webpack://./src/components/ui/Announcement.module.scss"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,gBAAgB;EAChB,eAAe,EAAA;;AAGjB;EACE,2BAA2B;EAC3B,kBAAkB;EAClB,gBAAgB,EAAA;;AAGlB;EACE,YAAY;EACZ,gBAAgB,EAAA;;AAGlB;EACE,qBAAqB;EACrB,kBAAkB;EAClB,4BAA4B,EAAA","sourcesContent":[".container{\n  margin-top: 10vh;\n  max-height: 80vh;\n  max-width: 80vw;\n}\n\n.header{\n  margin: 20px 10px 10px 10px;\n  font-size: x-large;\n  font-weight: 700;\n}\n\n.content{\n  margin: 10px;\n  font-size: large;\n}\n\n.item{\n  list-style-type: disc;\n  display: list-item;\n  list-style-position: outside;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `Announcement_container__nsbDi`,
	"header": `Announcement_header__GEazL`,
	"content": `Announcement_content__jm2AL`,
	"item": `Announcement_item__i0dBT`
};
export default ___CSS_LOADER_EXPORT___;
