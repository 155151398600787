import React from "react";
import ButtonIcon from "./ButtonIcon";
import styles from "./CircleButton.module.scss";

function CircleButton(props) {
  const performAction = props.playerAction;
  const buttonType = props.buttonType;
  const buttonText = props.buttonText;
  const withIcon = props.withIcon;
  const dataYear = props.dataYear;
  // const btnColor = '#39384e';
  const btnColor = '#2C2B3B';

  return (
    <div className={styles.button} data-filter={dataYear} onClick={performAction}>
      <div className="flex justify-center">
        { withIcon ? 
          < ButtonIcon buttonType={buttonType}/> 
          : 
          <p className={styles.text}>{buttonText}</p>}
      </div>
      <svg width="62" height="62" viewBox="0 0 62 62">
        < ButtonIcon buttonType={buttonType}/>
        <circle
          cx="31"
          cy="31"
          r="31"
          fill={btnColor}
        />
      </svg>
    </div>
  );
}

export default CircleButton;
