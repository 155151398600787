// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ButtonIcon_icon_portrait__9DwDA {
  padding-left: calc((3vh + 3vw)/2);
  padding-top: calc((4.5vh + 4.5vw)/2);
  z-index: 10;
  overflow: hidden; }

.ButtonIcon_icon_landscape__KbqjE {
  padding-left: 2vw;
  padding-top: 5.75vh;
  z-index: 10;
  overflow: hidden; }

.ButtonIcon_emoji_portrait__gQY5F {
  font-size: calc((5vh + 5vw)/2);
  position: absolute;
  top: 6vw;
  left: 10vw; }

.ButtonIcon_emoji_landscape__jNaU8 {
  font-size: 4vw;
  position: absolute;
  top: 2.5vw;
  left: 4.5vw; }

.ButtonIcon_fullscreen_landscape__AUEIn {
  margin-top: -1.25vh; }

.ButtonIcon_fullscreen_portrait__--EJ0 {
  margin-top: -0.65vh; }
`, "",{"version":3,"sources":["webpack://./src/components/ui/ButtonIcon.module.scss"],"names":[],"mappings":"AAAA;EAEE,iCAAiC;EACjC,oCAAoC;EACpC,WAAW;EACX,gBAAe,EAAA;;AAGjB;EACE,iBAAiB;EACjB,mBAAmB;EACnB,WAAW;EACX,gBAAe,EAAA;;AAGjB;EACE,8BAA8B;EAC9B,kBAAkB;EAClB,QAAQ;EACR,UAAU,EAAA;;AAGZ;EACE,cAAc;EACd,kBAAkB;EAClB,UAAU;EACV,WAAW,EAAA;;AAGb;EACE,mBAAmB,EAAA;;AAGrB;EACE,mBAAmB,EAAA","sourcesContent":[".icon_portrait{\n  // padding-left: 4.5vw;\n  padding-left: calc((3vh + 3vw)/2);\n  padding-top: calc((4.5vh + 4.5vw)/2);\n  z-index: 10;\n  overflow:hidden;\n}\n\n.icon_landscape{\n  padding-left: 2vw;\n  padding-top: 5.75vh;\n  z-index: 10;\n  overflow:hidden;\n}\n\n.emoji_portrait {\n  font-size: calc((5vh + 5vw)/2);\n  position: absolute;\n  top: 6vw;\n  left: 10vw;\n}\n\n.emoji_landscape {\n  font-size: 4vw;\n  position: absolute;\n  top: 2.5vw;\n  left: 4.5vw;\n}\n\n.fullscreen_landscape {\n  margin-top: -1.25vh;\n}\n\n.fullscreen_portrait {\n  margin-top: -0.65vh;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"icon_portrait": `ButtonIcon_icon_portrait__9DwDA`,
	"icon_landscape": `ButtonIcon_icon_landscape__KbqjE`,
	"emoji_portrait": `ButtonIcon_emoji_portrait__gQY5F`,
	"emoji_landscape": `ButtonIcon_emoji_landscape__jNaU8`,
	"fullscreen_landscape": `ButtonIcon_fullscreen_landscape__AUEIn`,
	"fullscreen_portrait": `ButtonIcon_fullscreen_portrait__--EJ0`
};
export default ___CSS_LOADER_EXPORT___;
